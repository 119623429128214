import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from "./router";
import i18n from "./translation";
import { FirebaseAppProvider } from 'reactfire';
import store from './redux/store';
import { Provider } from 'react-redux';

const firebaseConfig = {
  apiKey: "AIzaSyBSgEF6BZHrrxM0svBSUcIViV2khbkTGcw",
  authDomain: "thebeautibulls.firebaseapp.com",
  projectId: "thebeautibulls",
  storageBucket: "thebeautibulls.appspot.com",
  messagingSenderId: "597225667061",
  appId: "1:597225667061:web:99ff3678fc7e4366af08bc",
  measurementId: "G-GHZ49J2M30"
};

const App = () => (
  <>
    <Provider store={store}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ToastContainer pauseOnFocusLoss={false} />
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Router />
          </I18nextProvider>
        </BrowserRouter>
      </FirebaseAppProvider>
    </Provider>
  </>
);

ReactDOM.render(<App />, document.getElementById("root"));
