import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Inter Light";
        src: url("/fonts/Inter-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Inter Bold";
        src: url("/fonts/Inter-Bold.ttf") format("truetype");
        font-style: bold;
    }


    body,
    html,
    a {
        font-family: 'Inter Light', 'Inter Bold', sans-serif;
    }

    :root {
        --secondary-2: #18191B;
        --body-color: #000000;
        --primary-color: linear-gradient(121.31deg, #4F7EFF 11.73%, #FF00FF 49.11%, #FFDD47 86.78%);
        --text-color: #FFFFFF;
    } 

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: var(--body-color);
        overflow-x: hidden;
        color : var(--text-color);
        height:auto!important;
        min-height:100vh;
    }

    a:hover {
        color: var(--text-color);
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6 {
    //     color: var(--text-color);
    //     font-size: 56px;
    //     line-height: 1.18;

    //     @media only screen and (max-width: 890px) {
    //       font-size: 47px;
    //     }
      
    //     @media only screen and (max-width: 414px) {
    //       font-size: 32px;
    //     }
    // }

    p {
        color: var(--text-color);
        // font-size: 21px;        
        // line-height: 1.41;
    }

    // h1 {
    //     font-weight: 900;
    // }

    a {
        text-decoration: none;
        outline: none;
        color: var(--text-color);

        :hover {
            color: var(--text-color);
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .smooth-red-gradient {
        background: linear-gradient(108.2deg, #EF060F -1.69%, #B60CF2 114.66%);
    }
    .smooth-green-gradient {
        background: linear-gradient(110.7deg, #49EBF5 -9.14%, rgba(4, 219, 25, 0.7) 136.77%);
    }
    .smooth-white-gradient {
        background: linear-gradient(110.7deg, #FFFFFF -9.14%, #D9A8FF 136.77%);
    }
    .smooth-purple-gradient {
        background: linear-gradient(70.91deg, #764BA2 0%, #667EEA 100%);
    }
    .smooth-blue-gradient {
        background: linear-gradient(111.75deg, #0A15DB -4.2%, #20F8FA 100%);
    }
    .smooth-yellow-gradient {
        background: linear-gradient(118.19deg, #FFF73E 13.46%, #C06FFF 93.94%);
    }
    .primary{
        background: var(--primary-color)
    }
    .secondary-1{
        background: var(--body-color);
    }
    .secondary-2{
        background: var(--secondary-2);
    }
    .secondary-3{
        background: #FFFFFF;
    }
    .neutral-1{
        background: #B2B2B2;
    }
    .neutral-2{
        background: #777777;
    }
    .neutral-3{
        background: #535353;
    }

    .bottom-right {
        position: absolute;
        bottom: 10px;
        right: 70px;
    }
    
    .bottom-right-bull {
        position: absolute;
        bottom: 5px;
        right: 500px;
    }

    .button-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-left {
        text-align : left;
    }
    
    .text-center {
        text-align : center;
    }
    
    .text-right {
        text-align : right;
    }

    .ant-timeline-item-head {
        background-color : transparent  !important;
    }

    .ant-timeline-item-label{
        margin-left: 50px;
        margin-top: -40px;
    }

    .mt-5{
        margin-top : 5px;
    }
    .mt-10{
        margin-top : 10px;
    }
    .mt-15{
        margin-top : 15px;
    }
    .mt-20{
        margin-top : 20px;
    }
    .mt-25{
        margin-top : 25px;
    }
    .mt-30{
        margin-top : 30px;
    }
   
    .mb-5{
        margin-bottom : 5px;
    }
    .mb-10{
        margin-bottom : 10px;
    }
    .mb-15{
        margin-bottom : 15px;
    }
    .mb-20{
        margin-bottom : 20px;
    }
`;
