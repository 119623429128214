const routes = [
  // {
  //   path: ["/"],
  //   exact: true,
  //   component: "ComingSoon",
  // },
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  // {
  //   path: ["/galery"],
  //   exact: true,
  //   component: "Galery",
  // },
  // {
  //   path: ["/galery/:id"],
  //   exact: true,
  //   component: "GaleryDescription",
  // },
  // {
  //   path: ["/faqs"],
  //   exact: true,
  //   component: "Faqs",
  // },
  // {
  //   path: ["/setting"],
  //   exact: true,
  //   component: "Setting",
  // },
  // {
  //   path: ["/myfarmily"],
  //   exact: true,
  //   component: "Myfarmily",
  // },
  // {
  //   path: ["/whitelist-minting"],
  //   exact: true,
  //   component: "WhiteListMinting",
  // },
  // {
  //   path: ["/presale-minting"],
  //   exact: true,
  //   component: "PresaleMinting",
  // },
  // {
  //   path: ["/terms-conditions"],
  //   exact: true,
  //   component: "TermsConditions",
  // },
];

export default routes;
