export interface UserState {
    data: string[];
}

const initialState = {
    data: [],
};

export type Action = { type: "ADD_DATA"; payload: string };

export const userReducer = (
    state: UserState = initialState,
    action: Action
) => {
    switch (action.type) {
        case "ADD_DATA": {
            return { ...state, data: [...state.data, action.payload] };
            }
        default:
            return state;
    }
};
