import { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import Footer from "../components/Footer";
// import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Footer = lazy( () => import('../components/Footer') );
const Header = lazy( () => import('../components/Header') );

const Router = () => {
  return (
    <Suspense fallback={loading}>
      <Styles />
      <Header />
      {/* <BrowserRouter> */}
        <Switch>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`../pages/${routeItem.component}`))}
              />
            );
          })}
          <Route path="/404" component={lazy(() => import(`../pages/404`))} />
          <Route exact path="/discord" render={() => ((window.location as any) = "https://www.discord.gg/thebeautibulls")} />
          <Redirect to="/404" />
        </Switch>
      {/* </BrowserRouter> */}
      <Footer />
    </Suspense>
  );
};

export default Router;
